import axios from "axios";
let controller = new AbortController();
let signal = controller.signal;
export default {
    async getProperties() {
        return (
          await axios.get("/api/property", {
            signal: signal,
          })
        ).data;
      },
};
