<template>
  <div>
    <Header  :properties="properties" @searchInput="changeSearch"  />
    <Content
      v-if="properties"
      :active-view="activePropertiesView"
      :properties="properties"
      :search="searchInput"
    />
  </div>
</template>

<script>
import Header from "@/components/PropertyOwner/Header.vue";
import Content from "@/components/PropertyOwner/Content.vue";
import { mapGetters, mapActions } from "vuex";
//import axios from "axios";
import propertyApi from "@/http/property";

export default {
  name: "PropertyOwner",
  components: {
    Header,
    Content,
  },
  data() {
    return {
      searchInput: "",
      properties: null,
      //propertyOwnerUpdated: 0,
      //addUserPanelOpen: false,
      //userSidepanelClosed: true,
    };
  },
  mounted() {},
  async created() {
    this.changeSidepanelStateProperty(1);
    this.changeSidepanelStateProperties(0);
    if (!this.$route.params.id) {
      this.changeSidepanelStateProperty(0);
      window.history.replaceState({}, document.title, "/property-owner");
    }
    await this.getProperties();
  },
  computed: {
    ...mapGetters([
      "filteredUsers",
      "sidepanelProperty",
      "activePropertiesView",
    ]),
  },
  methods: {
    ...mapActions([
      "changeSidepanelStateProperties",
      "changeSidepanelStateProperty",
    ]),
    async getProperties() {
      this.properties = await propertyApi.getProperties();
      console.log("PROPERTIES", this.properties)
    },
    changeSearch(val) {
      this.searchInput = val;
    },
    /*  changeView(view) {
      this.activeView = view;
    },
    addUserPanel(value) {
      this.addUserPanelOpen = value;
    },
    userSidepanelClose() {
      this.addUserPanelOpen = false;
    }, */
  },
};
</script>

<style lang="scss" scoped></style>
