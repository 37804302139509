var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "deal-list-content"
  }, _vm._l(_vm.properties, function (property) {
    return _c('ListCard', {
      key: property._id,
      staticClass: "cursor-move",
      attrs: {
        "property": property,
        "id": property._id
      },
      on: {
        "cardClick": function (propertyId) {
          return _vm.$emit('cardClick', propertyId);
        }
      }
    });
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }