<template>
  <div class="content">
    <div
      style="margin-top: 32px; position: absolute; top: 30%; left: 50%"
      v-if="!usableProperties"
      class="loader"
    />
    <div v-if="usableProperties.length < 1" class="no-results-wrapper">
      <p v-if="!search" class="heading-geo32 no-results">
        {{ $t("AddAPropertyToStart") }}
      </p>
      <p v-if="search" class="heading-geo32 no-results">
        {{ $t("NoResultsFound") }}
      </p>
     <!--  <p
        class="clear-filters-button"
        @click="removeFiltersProperties"
        v-if="search"
      >
        {{ $t("ClearAllFilters") }}
      </p> -->
    </div>
    <GridContent
      v-if="activeView === 'GridView' && usableProperties.length"
      :taskUpdater="taskUpdater"
      :properties="usableProperties"
      @cardClick="cardClick"
    />

    <ListContent
      v-if="activeView === 'ListView' && usableProperties.length"
      :taskUpdater="taskUpdater"
      :properties="properties"
      @cardClick="cardClick"
    />
  </div>
</template>

<script>
import ListContent from "@/components/PropertyOwner/ListContent";
import GridContent from "@/components/PropertyOwner/GridContent";
//import axios from "axios";
import { mapActions } from "vuex";

export default {
  name: "App",
  components: {
    ListContent,
    GridContent,
  },
  props: ["activeView", "properties", "search"],
  data() {
    return {
      arranged: false,
      originalArr: null,
      usableProperties: null,
      lastMovedProperty: null,
      pipelinesWithProperties: null,
      taskUpdater: 0,
    };
  },
  watch: {
    handler() {
      this.$forceUpdate();
    },
    search() {
      this.originalArr = [...this.properties];
      if (this.search) {
        let searchValue = this.search.toLowerCase();
        let tempItemsTitle = this.originalArr.filter(
          (item) => item.name.toLowerCase().indexOf(searchValue) > -1
        );
        this.usableProperties = [...tempItemsTitle];
      } else {
        this.usableProperties = this.originalArr;
      }
    },
    // "task.dealType": function () {
    //   this.openAml();
    // },
  },
  created() {
    this.initData();
  },
  mounted() {
    if (this.properties != null) this.initData();
  },
  methods: {
    ...mapActions(["changeSidepanelStateProperty", "removeFiltersProperties"]),
    openProperty() {
      this.$router.push({
        name: "PropertyDetail",
        params: { id: this.lastMovedProperty._id },
      });
    },
    initData() {
      console.log("Läks", this.properties);
      this.usableProperties = [...this.properties];
      this.originalArr = [...this.properties];
      console.log("USABLE", this.usableProperties);
    },

    cardClick(propertyId) {
      this.changeSidepanelStateProperty();
      console.log("PROPERTYID", propertyId)
      this.$router.push({
        name: "PropertyDetail",
        params: { id: propertyId },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.no-results-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 48px 48px 48px 0px;
  flex-grow: 0;
  margin: 24px 112px;
}
</style>
