var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticStyle: {
      "height": "2px",
      "background": "#f4f5f7",
      "width": "100%"
    }
  }), _c('div', {
    staticClass: "deal-list-card-content",
    on: {
      "click": function ($event) {
        return _vm.$emit('cardClick', _vm.property._id);
      }
    }
  }, [_c('span', {
    staticClass: "deal-image-con"
  }, [_vm.property.images[0] ? _c('img', {
    staticClass: "deal-image",
    attrs: {
      "src": _vm.property.images[0],
      "alt": ""
    }
  }) : _vm._e(), !_vm.property.images.length ? _c('span', {
    staticClass: "deal-image",
    staticStyle: {
      "background": "#f4f5f7"
    }
  }) : _vm._e()]), _c('span', {
    staticClass: "deal-main-info-con"
  }, [_c('div', {
    staticClass: "content-bold"
  }, [_vm._v(" " + _vm._s(_vm.property.name) + " ")])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }