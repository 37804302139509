<template>
  <div class="header" style="background-color: white">
    <v-row class="title-row">
      <h3 class="header-title">{{ $t("MyAssets") }}</h3>
      <AddNewButton />
    </v-row>
    <HeaderToolbar
      :module="'properties-kanban'"
      style="justify-content: space-between"
    >
      <v-row>
        <button
          @click="changeSidepanelStateProperties()"
          class="outlined-small-button"
          style="background-color: black !important"
        >
          <img
            src="../../assets/images/plusSign.svg"
            style="
              filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg)
                brightness(103%) contrast(102%);
            "
            alt=""
          />
          <p
            style="
              filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg)
                brightness(103%) contrast(102%);
            "
          >
            {{ $t("AddProperty") }}
          </p>
        </button>

        <img class="point-separator" src="../../assets/images/•.svg" alt="" />

        <div style="margin-left: 0px">
          <v-text-field
            autocomplete="off"
            outlined
            v-model="search"
            hide-details
            :id="'search-bar'"
            prepend-inner-icon="mdi-magnify"
            :placeholder="$t('Search') + '...'"
            style="background: white"
          >
          </v-text-field>
        </div>
      </v-row>

      <span style="display: flex; gap: 8px">
        <button
          class="outlined-small-button d-inline-flex"
          @click="setActivePropertiesView('GridView')"
          :style="
            activePropertiesView === 'GridView'
              ? 'background-color: #F4F5F7 !important;'
              : null
          "
        >
          <img
            src="../../assets/images/grid_view2.svg"
            alt=""
            class="view-button"
          />
          <p class="button-text">
            {{ $t("Grid") }} {{ $t("View").toLowerCase() }}
          </p>
        </button>

     <!--    <button
          class="outlined-small-button d-inline-flex"
          @click="setActivePropertiesView('ListView')"
          :style="
            activePropertiesView === 'ListView'
              ? 'background-color: #F4F5F7 !important;'
              : null
          "
        >
          <img
            src="../../assets/images/list_view.svg"
            alt=""
            class="view-button"
          />
          <p class="button-text">
            {{ $t("List") }} {{ $t("View").toLowerCase() }}
          </p>
        </button> -->
      </span>
    </HeaderToolbar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AddNewButton from "@/components/common/AddNew";
import HeaderToolbar from "@/components/common/HeaderToolbar";

export default {
  name: "Kliendiregister",
  components: { AddNewButton, HeaderToolbar },
  props: ["properties"],
  data() {
    return {
      view: "Kanban",
      search: "",
    };
  },
  created() {},
  mounted() {},
  watch: {
    search: function (val) {
      this.$emit("searchInput", val);
    },
    projectsFiltersReset() {
      this.search = "";
    },
  },
  computed: {
    ...mapGetters(["activePropertiesView", "propertiesFiltersReset"]),
  },
  methods: {
    ...mapActions([
      "changeSidepanelStateProperties",
      "changeSidepanelStateProperty",
      "setActivePropertiesView",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.view-button {
  filter: brightness(0);
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.button-text {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;
  text-align: center;
}

.v-btn {
  margin-left: 12px !important;
  background-color: transparent !important;
  box-shadow: 0px 0 2px rgba(28, 14, 3, 0.3);
  text-transform: none;
}

.v-list-item {
  .v-list-item--link {
    .theme--light div {
      display: flex;
    }
  }
}

.col {
  padding: 0;
}

.left-row {
  justify-content: flex-start;

  .v-select {
    max-width: 250px;
  }

  .sector-type {
    margin-right: 42px;
  }
}

.right-row {
  justify-content: flex-end;

  .autocomplete {
    max-width: 300px;
  }

  .icon-button {
    border: 1px solid #e4e4e4;
    height: 40px;
    width: 40px;
    display: flex;
    outline: none;
    padding: 16px;
    align-items: center;
    user-select: none;
    margin-right: 16px;
    margin-left: 16px;
    border-radius: 8px;

    justify-content: center;
  }
}

.icon-button-active {
  background-color: #f4f5f7;
}

::v-deep .v-text-field .v-input__control .v-input__slot {
  min-height: 34px !important;
  max-height: 34px !important;
}

::v-deep .v-text-field--enclosed .v-input__prepend-inner {
  margin-top: 5.5px;
}

::v-deep .v-icon.v-icon {
  font-size: 18px;
}

::v-deep .theme--light.v-icon {
  color: black;
}

::v-deep .v-text-field__slot input {
  font: revert !important;
}
</style>
