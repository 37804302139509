var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "property-grid-card-content",
    on: {
      "click": function ($event) {
        return _vm.$emit('cardClick', _vm.property._id);
      }
    }
  }, [_c('div', {
    staticStyle: {
      "width": "224px",
      "height": "156px"
    }
  }, [_vm.property.images && _vm.property.images.length ? _c('img', {
    staticClass: "image-available",
    attrs: {
      "src": _vm.property.images[0].name,
      "alt": ""
    }
  }) : _vm._e(), !_vm.property.images || !_vm.property.images.length ? _c('div', {
    staticClass: "empty-image"
  }) : _vm._e()]), _c('div', {
    staticStyle: {
      "max-height": "40px"
    }
  }, [_vm.property.name ? _c('v-row', {
    staticClass: "content-bold",
    staticStyle: {
      "margin-top": "8px"
    }
  }, [_c('h5', [_vm._v(_vm._s(_vm.property.name))])]) : _vm._e()], 1), _c('div', {
    staticStyle: {
      "margin-top": "32px"
    }
  }, [_vm.property.area ? _c('v-row', {
    staticClass: "user-field-row",
    staticStyle: {
      "margin-top": "0px"
    }
  }, [_c('p', {
    staticClass: "content-small field-name"
  }, [_vm._v(_vm._s(_vm.$t("PropertyArea")))]), _c('p', {
    staticClass: "field-value content-small-semibold"
  }, [_vm._v(_vm._s(_vm.property.area) + " m²")])]) : _vm._e(), _vm.property.value ? _c('v-row', {
    staticClass: "user-field-row",
    staticStyle: {
      "margin-top": "8px"
    }
  }, [_c('p', {
    staticClass: "content-small field-name"
  }, [_vm._v(_vm._s(_vm.$t("Value")))]), _c('p', {
    staticClass: "field-value content-small-semibold"
  }, [_vm._v(_vm._s(_vm.property.value) + " €")])]) : _vm._e(), _c('v-row', {
    staticClass: "user-field-row",
    staticStyle: {
      "margin-top": "8px"
    }
  }, [_c('p', {
    staticClass: "content-small field-name"
  }, [_vm._v(_vm._s(_vm.$t("Buildings")))]), _c('p', {
    staticClass: "field-value content-small-semibold"
  }, [_vm._v(" " + _vm._s(_vm.property.buildings.length) + " ")])]), _c('v-row', {
    staticClass: "user-field-row",
    staticStyle: {
      "margin-top": "8px"
    }
  }, [_c('p', {
    staticClass: "content-small field-name"
  }, [_vm._v(_vm._s(_vm.$t("ActiveTenants")))]), _c('p', {
    staticClass: "field-value content-small-semibold"
  }, [_vm._v(" " + _vm._s(_vm.propertyItem.tenantsCounter) + " ")])])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }