var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Header', {
    attrs: {
      "properties": _vm.properties
    },
    on: {
      "searchInput": _vm.changeSearch
    }
  }), _vm.properties ? _c('Content', {
    attrs: {
      "active-view": _vm.activePropertiesView,
      "properties": _vm.properties,
      "search": _vm.searchInput
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }