<template>
  <div id="projects-kanban-content" class="deal-grid-content">
      <GridCard
        v-for="property in properties"
        :key="property._id"
        :property="property"
        :id="property._id"
        @cardClick="(propertyId) => $emit('cardClick', propertyId)"
        style="margin-top: 12px"
      />
    </div>
</template>
<script>
import GridCard from "./GridCard.vue";
import { mapActions } from "vuex";

export default {
  name: "GridContent",
  components: {
    GridCard,
  },
  props: ["taskUpdater", "properties"],
  data() {
    return {};
  },
  mounted() {},
  created() {
    console.log("PROPERTIES", this.properties)
  },
  watch: {},
  methods: {
    ...mapActions(["changeSidepanelStateProperties", "changeSidepanelStateProperty"]),
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/DealCard";

.deal-grid-content {
  display: flex;
  background-color: #f4f5f7;
  flex-wrap: wrap;
  gap: 16px;
  padding: 24px 48px 72px 112px;
}

.deal-pipeline {
  min-width: 296px;
  height: 80%;
}

.pipeline-sub-info {
  color: #939597;
  gap: 8px;
  margin-top: 2px;
}

.dot {
  height: 5px;
  width: 5px;
  background-color: #939597;
  border-radius: 50%;
  align-self: center;
}

.scrollable {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100%;
  min-height: 100%;
  margin-top: 12px;
}
</style>
