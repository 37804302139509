<template>
  <div>
    <div style="height: 2px; background: #f4f5f7; width: 100%;" />

    <div
      @click="$emit('cardClick', property._id)"
      class="deal-list-card-content"
    >
      <span class="deal-image-con">
        <img
          v-if="property.images[0]"
          class="deal-image"
          :src="property.images[0]"
          alt=""
        />
        <span
          v-if="!property.images.length"
          class="deal-image"
          style="background: #f4f5f7"
        />
      </span>

      <span class="deal-main-info-con">
        <div class="content-bold">
          {{ property.name }}
        </div>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    property: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    unfinishedCounter: 0,
  }),
  created() {
  },
  mounted() {},
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "src/assets/css/DealCard";

.deal-list-card-content {
  padding: 0 8px;
  cursor: pointer;
  display: flex;
  background-color: #FFFFFF;
  border-radius:8px;
  gap:8px
}

.deal-image-con {
  padding: 8px;
  min-width: 88px;
}

.deal-image {
  width: 72px;
  height: 56px;
  border-radius: 8px;
  display: block;
}

.deal-main-info-con {
  min-width: 496px;
  display: grid;
  align-self: center;
  padding: 0 8px;
}

.deal-sub-info-con {
  min-width: 210px;
  min-height: 72px;
  display: flex;
  align-items: center;
  padding: 0 8px;
}
.tasks-files-row {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
</style>
