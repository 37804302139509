<template>
  <div class="deal-list-content">
        <ListCard
          v-for="property in properties"
          :key="property._id"
          :property="property"
          :id="property._id"
          @cardClick="(propertyId) => $emit('cardClick', propertyId)"
          class="cursor-move"
        />
    </div>
</template>
<script>
import ListCard from "@/components/PropertyOwner/ListCard";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ListContent",
  components: {
    ListCard,
  },
  props: ["properties"],
  data() {
    return {};
  },
  mounted() {},
  created() {},
  watch: {},
  computed: {
    ...mapGetters(["projectListViewStates"]),
  },
  methods: {
    ...mapActions(["setProjectListViewState"]),
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/DealCard";

.deal-list-content {
  max-width: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap:4px;
  padding: 24px 48px 24px 112px;
}

.deal-pipeline {
  width: 100%;
  padding-bottom: 40px;
}

.deal-header {
  font-family: Geomanist;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;

  margin-bottom: 16px;
  cursor: pointer;
  display: flex;
  gap: 8px;
}

.scrollable {
  border-radius: 8px;
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04), 0 1px 2px rgba(0, 0, 0, 0.03),
    0 0 1px rgba(0, 0, 0, 0.04);
}
</style>
