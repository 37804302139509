<template>
  <div
    class="property-grid-card-content"
    @click="$emit('cardClick', property._id)"
  >
    <div style="width: 224px; height: 156px">
      <img
        v-if="property.images && property.images.length"
        class="image-available"
        :src="property.images[0].name"
        alt=""
      />
      <div
        v-if="!property.images || !property.images.length"
        class="empty-image"
      ></div>
    </div>
    <div style="max-height: 40px">
      <v-row v-if="property.name" class="content-bold" style="margin-top: 8px">
        <h5>{{ property.name }}</h5>
      </v-row>
    </div>

    <div style="margin-top: 32px">
      <v-row
        style="margin-top: 0px"
        v-if="property.area"
        class="user-field-row"
      >
        <p class="content-small field-name">{{ $t("PropertyArea") }}</p>
        <p class="field-value content-small-semibold">{{ property.area }} m²</p>
      </v-row>
      <v-row
        style="margin-top: 8px"
        v-if="property.value"
        class="user-field-row"
      >
        <p class="content-small field-name">{{ $t("Value") }}</p>
        <p class="field-value content-small-semibold">{{ property.value }} €</p>
      </v-row>
      <v-row style="margin-top: 8px" class="user-field-row">
        <p class="content-small field-name">{{ $t("Buildings") }}</p>
        <p class="field-value content-small-semibold">
          {{ property.buildings.length }}
        </p>
      </v-row>
      <v-row style="margin-top: 8px" class="user-field-row">
        <p class="content-small field-name">{{ $t("ActiveTenants") }}</p>
        <p class="field-value content-small-semibold">
          {{ propertyItem.tenantsCounter }}
        </p>
      </v-row>
    </div>
  </div>
</template>
<script>
//import propertyApi from "@/http/property";
export default {
  components: {},
  props: {
    property: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    unfinishedCounter: 0,
    propertyItem: null,
  }),
  created() {
    this.propertyItem = this.property;
    this.countBuildingContracts();
  },
  mounted() {},
  computed: {},
  methods: {
    countBuildingContracts() {
      this.propertyItem.tenantsCounter = 0;
      this.propertyItem.buildings.forEach((building) => {
        if (building.units.length) {
          building.units.forEach((unit) => {
            if (unit.contract && unit.contract.length) {
              unit.contract.forEach((contract) => {
                if (contract.length !== 0 && contract.statusCode !== 0) {
                  this.propertyItem.tenantsCounter++;
                }
              });
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/css/DealCard";

.property-grid-card-content {
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  cursor: pointer;
  height: 340px;
  width: 256px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04), 0 1px 2px rgba(0, 0, 0, 0.03),
    0 0 1px rgba(0, 0, 0, 0.04);
}
.empty-image {
  height: 156px;
  width: 224px;
  border-radius: 8px;
  background-color: #f4f5f7;
}
.image-available {
  height: 156px;
  width: 224px;
  border-radius: 8px;
}
.field-name {
  width: 128px;
}
.field-value {
  width: 94px;
  justify-content: flex-end;
}
</style>
